import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Routes from '~/routes';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';

const App: React.FC = () => {
  const [version, setVersion] = useState(null);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const res = await fetch('/version.json', { cache: 'no-store' });
        const data = await res.json();

        if (version && data.version !== version) {
          window.location.reload(); // Atualiza a página
        }

        setVersion(data.version);
      } catch (error) {
        // console.error('Erro ao verificar versão:', error);
      }
    };

    checkVersion();
    const interval = setInterval(checkVersion, 5000);

    return () => clearInterval(interval);
  }, [version]);

  return (
    <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>
        <GlobalStyles />
      </BrowserRouter>
    </HttpsRedirect>
  );
};

export default App;
