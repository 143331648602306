import styled from 'styled-components';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  notShow?: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-color: #984f0e;
  min-width: 980px;
  min-height: 551.25px;
`;

export const Slide1 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-color: #6a390b;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .logo {
      width: 84.44px;
      height: 25.31px;
      position: absolute;
      left: 86.48px;
      top: 59.72px;
    }

    .title {
      font-size: 45px;
      color: #fff;
      font-weight: 600;
      width: 403.85px;
      position: absolute;
      left: 79.99px;
      top: 136.97px;
      line-height: 40.95px;
    }

    .content {
      font-size: 30px;
      color: #d48b4b;
      font-weight: 400;
      width: 426.11px;
      position: absolute;
      left: 86.48px;
      top: 289.81px;
      line-height: 25.8px;
    }

    .btn-indicate {
      background-color: #be7e38;
      border-radius: 20px;
      font-size: 24px;
      color: #421f00;
      font-weight: 500;
      width: 290px;
      height: 77px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 86.48px;
      top: 387.38px;
    }

    .woman {
      position: absolute;
      left: 573.1px;
      top: 78.93px;
      width: 361.06px;
      height: 393.14px;
    }

    .smile {
      position: absolute;
      left: 301px;
      top: 464.5px;
      width: 679px;
      height: 87px;
    }
  }
`;

export const Slide2 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .waves {
      position: absolute;
      left: 0;
      top: 0;
      width: 980px;
      height: 551.25px;
    }

    .indicate,
    .earned {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
        color: #fff;
      }

      p:first-child {
        font-weight: 600;
        font-size: 50px;
        line-height: 43px;
        margin-bottom: 23px;
      }

      p:last-child {
        font-weight: 400;
        font-size: 24px;
        line-height: 20.64px;
      }
    }

    .indicate {
      left: 136px;
      top: 232.5px;
      width: 270.32px;
      height: 129.46px;
    }

    .arrow {
      position: absolute;
      left: 438.84px;
      top: 277.25px;
    }

    .earned {
      left: 563.12px;
      top: 232.5px;
      width: 309.03px;
      height: 150.46px;
    }
  }
`;

export const Slide3 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .bg-stars {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 980px;
      height: 551.25px;
    }

    .title {
      position: absolute;
      left: 142.08px;
      top: 232.5px;
      font-size: 50px;
      font-weight: 600;
      width: 695.84px;
      color: #fff;
      line-height: 43px;
      text-align: center;
    }
  }
`;

export const Slide4 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .title {
      position: absolute;
      left: 54.03px;
      top: 73.14px;
      width: 236px;
      height: 50.39px;
      display: flex;
      flex-direction: column;

      p:first-child {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        line-height: 25.8px;
      }

      p:last-child {
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        line-height: 15.48px;
      }
    }

    .percent {
      position: absolute;
      left: 54.03px;
      top: 289px;
      width: 144.46px;
      height: 80.36px;
      font-size: 106px;
      font-weight: 600;
      color: #fff;
    }

    .text {
      position: absolute;
      left: 54.03px;
      top: 426.26px;
      width: 266.63px;
      font-size: 24px;
      font-weight: 400;
      color: #ddaa7c;
      line-height: 20.64px;
    }

    .bg-brown {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 614px;
      height: 551.25px;
      background-color: #be7e38;
    }

    .name {
      position: absolute;
      top: 105.92px;
      font-size: 24px;
      font-weight: 500;
      color: #fff;
    }

    .people-joao {
      left: 449.47px;
    }

    .joao {
      position: absolute;
      left: 436.45px;
      top: 151.97px;
      width: 88.75px;
      height: 254.33px;
    }

    .indicate {
      position: absolute;
      left: 578.81px;
      top: 193.48px;
      width: 67px;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
    }

    .arrow-right {
      position: absolute;
      left: 665.52px;
      top: 185.5px;
      width: 62px;
      height: 29px;
    }

    .earned {
      position: absolute;
      left: 578.81px;
      top: 312.02px;
      width: 60px;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
    }

    .arrow-left {
      position: absolute;
      left: 665.52px;
      top: 309.5px;
      width: 62px;
      height: 29px;
    }

    .people-maria {
      left: 803.28px;
    }

    .maria {
      position: absolute;
      left: 786.9px;
      top: 151.97px;
      width: 94.25px;
      height: 254.33px;
    }

    .closed {
      position: absolute;
      left: 759.8px;
      top: 430.99px;
      width: 135.06px;
      height: 33.43px;
      font-size: 24px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #00a952;
      color: #fff;
      border-radius: 50px;
    }
  }
`;

export const Slide5 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .title {
      position: absolute;
      left: 54.03px;
      top: 73.14px;
      width: 236px;
      height: 50.39px;
      display: flex;
      flex-direction: column;

      p:first-child {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        line-height: 25.8px;
      }

      p:last-child {
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        line-height: 15.48px;
      }
    }

    .percent,
    .percent-2 {
      position: absolute;
      left: 43.51px;
      width: 97.59px;
      height: 54.29px;
      font-size: 72px;
      font-weight: 600;
      color: #fff;
    }

    .percent {
      top: 180px;
    }

    .text,
    .text-2 {
      position: absolute;
      left: 43.51px;
      width: 230.09px;
      font-size: 20px;
      font-weight: 400;
      color: #ddaa7c;
      line-height: 17.2px;

      span {
        font-weight: 500;
      }
    }

    .text {
      top: 271.04px;
    }

    .percent-2 {
      top: 350px;
    }

    .text-2 {
      top: 441.38px;
    }

    .bg-brown {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 651.52px;
      height: 551.25px;
      background-color: #be7e38;
    }

    .name {
      position: absolute;
      top: 105.48px;
      font-size: 24px;
      font-weight: 500;
      color: #fff;
    }

    .people-joao {
      left: 414.28px;
    }

    .joao {
      position: absolute;
      left: 401.26px;
      top: 151.97px;
      width: 88.75px;
      height: 254.33px;
    }

    .indicate,
    .indicate-2 {
      position: absolute;
      top: 154.4px;
      width: 67px;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
    }

    .indicate {
      left: 519.58px;
    }

    .arrow-right,
    .arrow-right-2 {
      position: absolute;
      top: 178.5px;
      width: 63px;
      height: 27px;
    }

    .arrow-right {
      left: 528.03px;
    }

    .earned,
    .earned-2 {
      position: absolute;
      top: 325.48px;
      width: 60px;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
    }

    .earned {
      left: 526.53px;
    }

    .arrow-left,
    .arrow-left-2 {
      position: absolute;
      top: 351.5px;
      width: 63px;
      height: 27px;
    }

    .arrow-left {
      left: 528.03px;
    }

    .percent-earned,
    .percent-earned-2 {
      position: absolute;
      top: 390.6px;
      width: 57.5px;
      height: 29.93px;
      font-size: 40px;
      font-weight: 500;
      color: #fff;
    }

    .percent-earned {
      left: 527.78px;
    }

    .people-maria {
      left: 629.64px;
    }

    .maria {
      position: absolute;
      left: 615.34px;
      top: 151.97px;
      width: 94.25px;
      height: 254.33px;
    }

    .indicate-2 {
      left: 725.51px;
    }

    .arrow-right-2 {
      left: 734.03px;
    }

    .earned-2 {
      left: 736.43px;
    }

    .arrow-left-2 {
      left: 734.03px;
    }

    .percent-earned-2 {
      left: 739.52px;
    }

    .people-cleber {
      left: 811.25px;
    }

    .cleber {
      position: absolute;
      left: 811.25px;
      top: 151.97px;
      width: 82.68px;
      height: 254.33px;
    }

    .closed {
      position: absolute;
      left: 795.72px;
      top: 430.99px;
      width: 135.06px;
      height: 33.43px;
      font-size: 24px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #00a952;
      color: #fff;
      border-radius: 50px;
    }
  }
`;

export const Slide6 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .title {
      position: absolute;
      left: 188.23px;
      top: 94.29px;
      width: 603.54px;
      font-size: 50px;
      font-weight: 600;
      color: #fff;
      line-height: 46.5px;
    }

    .text {
      position: absolute;
      left: 188.23px;
      top: 218.5px;
      width: 603.54px;
      font-size: 70px;
      font-weight: 600;
      color: #f6bd7e;
      text-align: center;
      line-height: 65.1px;
    }

    .text-2 {
      position: absolute;
      left: 188.71px;
      top: 389.19px;
      width: 603.54px;
      font-size: 40px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      line-height: 37.2px;
    }

    .smile {
      position: absolute;
      left: 0px;
      top: 232.5px;
      width: 980px;
      height: 319px;
    }
  }
`;
