/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';

import {
  Container,
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
} from './styles';

import logo from '~/assets/tv/indication/logo.svg';
import woman from '~/assets/tv/indication/woman.png';
import semiSmile from '~/assets/tv/indication/semi-smile.svg';

import waves from '~/assets/tv/indication/waves.svg';

import bgStars from '~/assets/tv/indication/bg-stars.svg';

import joao from '~/assets/tv/indication/joao.svg';
import arrowRight from '~/assets/tv/indication/arrow-right.svg';
import arrowLeft from '~/assets/tv/indication/arrow-left.svg';
import maria from '~/assets/tv/indication/maria.svg';

import cleber from '~/assets/tv/indication/cleber.svg';

import smile from '~/assets/tv/indication/smile.svg';

interface IIndication {
  show: boolean;
  startAnim: boolean;
}

interface ISlide {
  slide: string;
  slideTime: number;
  beforeTime?: number;
  afterTime?: number;
  bgSlide?: string;
}

const Indication: React.FC<IIndication> = ({ show, startAnim }) => {
  const [slideSelected, setSlideSelected] = useState({} as ISlide);
  const [showBefore, setShowBefore] = useState('');
  const [animSlideSelected, setAnimSlideSelected] = useState('');
  const [endAnimSlideSelected, setEndAnimSlideSelected] = useState('');

  const slides = useMemo<ISlide[]>(
    () => [
      { slide: 'Slide1', slideTime: 10000 },
      { slide: 'Slide2', slideTime: 10000 },
      { slide: 'Slide3', slideTime: 7500 },
      { slide: 'Slide4', slideTime: 12500 },
      { slide: 'Slide5', slideTime: 15000 },
      { slide: 'Slide6', slideTime: 10000 },
    ],
    []
  );

  const changeSlide = useCallback(
    (index) => {
      const { slide, slideTime } = slides[index];

      if (slides[index + 1] && slides[index + 1].beforeTime) {
        const nextSlide = slides[index + 1];
        if (nextSlide.beforeTime)
          setTimeout(() => {
            setShowBefore(nextSlide.slide);
            setAnimSlideSelected(nextSlide.slide);
          }, slideTime - nextSlide.beforeTime);
      }

      if (slides[index - 1] && slides[index - 1].afterTime) {
        const prevSlide = slides[index - 1];
        if (prevSlide.afterTime) {
          setEndAnimSlideSelected(prevSlide.slide);
          setSlideSelected(slides[index]);
          setTimeout(() => {
            setShowBefore('');
            setEndAnimSlideSelected('');
          }, prevSlide.afterTime);
        }
      } else {
        setSlideSelected(slides[index]);
      }
      setTimeout(() => {
        setAnimSlideSelected(slide);
        if (index + 1 < slides.length) {
          setTimeout(() => {
            changeSlide(index + 1);
          }, slideTime);
        } else {
          setTimeout(() => {
            setSlideSelected({} as ISlide);
          }, slideTime);
        }
      }, 100);
    },
    [slides]
  );

  useEffect(() => {
    if (show) {
      changeSlide(0);
    }
  }, [changeSlide, show]);

  return (
    <Container show={show} startAnim={startAnim}>
      <Slide1
        show={slideSelected.slide === 'Slide1'}
        startAnim={slideSelected.slide === 'Slide1'}
        notShow={slideSelected.slide !== 'Slide1'}
      >
        <div className="slide-container">
          <img src={logo} alt="Logo" className="logo" />
          <h2 className="title mb-0">
            Quer transformar seu sorriso sem gastar nada?
          </h2>
          <p className="content mb-0">
            Quanto mais você indicar, mais desconto você ganha
          </p>
          <p className="btn-indicate mb-0">Indique e participe</p>
          <img src={woman} alt="Woman" className="woman" />
          <img src={semiSmile} alt="Smile" className="smile" />
        </div>
      </Slide1>
      <Slide2
        show={slideSelected.slide === 'Slide2'}
        startAnim={slideSelected.slide === 'Slide2'}
      >
        <div className="slide-container">
          <img src={waves} alt="waves" className="waves" />
          <div className="indicate">
            <p>Ao indicar 1 amigo</p>
            <p>e ele fechar</p>
          </div>
          <FaArrowRightLong color="#fff" size={79} className="arrow" />
          <div className="earned">
            <p>Você ganha 6% do valor</p>
            <p>pra abater na sua mensalidade</p>
          </div>
        </div>
      </Slide2>
      <Slide3
        show={slideSelected.slide === 'Slide3'}
        startAnim={slideSelected.slide === 'Slide3'}
      >
        <div className="slide-container">
          <img src={bgStars} alt="Bg Stars" className="bg-stars" />
          <p className="title mb-0">
            Quanto mais você indica, mais você ganha!
          </p>
        </div>
      </Slide3>
      <Slide4
        show={slideSelected.slide === 'Slide4'}
        startAnim={slideSelected.slide === 'Slide4'}
      >
        <div className="slide-container">
          <div className="title">
            <p>Como funciona</p>
            <p>Indicações diretas</p>
          </div>
          <p className="percent">6%</p>
          <p className="text">do valor total do sistema de Maria</p>
          <div className="bg-brown" />
          <p className="name people-joao">João</p>
          <img src={joao} alt="João" className="joao" />
          <p className="indicate">Indicou</p>
          <img src={arrowRight} alt="Arrow Right" className="arrow-right" />
          <p className="earned">Ganha</p>
          <img src={arrowLeft} alt="Arrow Left" className="arrow-left" />
          <p className="name people-maria">Maria</p>
          <img src={maria} alt="Maria" className="maria" />
          <p className="closed">fechou</p>
        </div>
      </Slide4>
      <Slide5
        show={slideSelected.slide === 'Slide5'}
        startAnim={slideSelected.slide === 'Slide5'}
      >
        <div className="slide-container">
          <div className="title">
            <p>Como funciona</p>
            <p>Indicações indiretas</p>
          </div>
          <p className="percent">6%</p>
          <p className="text">
            Maria ganha 6% no valor total do sistema de{' '}
            <span className="text-white">Cleber</span>
          </p>
          <p className="percent-2">4%</p>
          <p className="text-2">
            João ganha 4% no valor total do sistema de{' '}
            <span className="text-white">Maria</span>
          </p>
          <div className="bg-brown" />
          <p className="name people-joao">João</p>
          <img src={joao} alt="João" className="joao" />
          <p className="indicate">Indicou</p>
          <img src={arrowRight} alt="Arrow Right" className="arrow-right" />
          <p className="earned">Ganha</p>
          <p className="percent-earned">4%</p>
          <img src={arrowLeft} alt="Arrow Left" className="arrow-left" />
          <p className="name people-maria">Maria</p>
          <img src={maria} alt="Maria" className="maria" />
          <p className="indicate-2">Indicou</p>
          <img src={arrowRight} alt="Arrow Right" className="arrow-right-2" />
          <p className="earned-2">Ganha</p>
          <p className="percent-earned-2">6%</p>
          <img src={arrowLeft} alt="Arrow Left" className="arrow-left-2" />
          <p className="name people-cleber">Cleber</p>
          <img src={cleber} alt="Cleber" className="cleber" />
          <p className="closed">fechou</p>
        </div>
      </Slide5>
      <Slide6
        show={slideSelected.slide === 'Slide6'}
        startAnim={slideSelected.slide === 'Slide6'}
      >
        <div className="slide-container">
          <p className="title">Você pode ganhar até</p>
          <p className="text">10% por indicação</p>
          <p className="text-2">Não fique de fora!</p>
          <img src={smile} alt="Smile" className="smile" />
        </div>
      </Slide6>
    </Container>
  );
};

export default Indication;
